import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay'
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core'
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { cloneValue } from 'utils/json-utils'
import { containsNormalizedText } from 'utils/string-utils'
import { HtButtonDSSize } from '../ht-button-ds/ht-button-ds.component'
import { DropdownSelectableItem } from '../ht-multiselect-dropdown/ht-multiselect-dropdown.component'

export interface SingleSelectDropdownData {
  items: DropdownSelectableItem[]
  title?: string
}

@Component({
  selector: 'ht-single-dropdown',
  templateUrl: './ht-single-dropdown.component.html',
  styleUrls: ['./ht-single-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtSingleSelectDropdownComponent),
      multi: true,
    },
  ],
})
export class HtSingleSelectDropdownComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input()
  hideArrow = false
  @Input()
  buttonSize?: HtButtonDSSize = HtButtonDSSize.MEDIUM
  @Input()
  hideLabel = false
  @Input()
  label?: string
  @Input()
  showSelectedValue = false
  @Input()
  searchBarPlaceholder?: string

  private _data: SingleSelectDropdownData[]
  @Input()
  set data(value) {
    this._data = value
    this.setInitialData(value)
    this.updateRadioChecked()
  }

  get data() {
    return this._data
  }

  @Output() applyChanges = new EventEmitter<SingleSelectDropdownData[]>()

  filteredData: SingleSelectDropdownData[]
  initialData: SingleSelectDropdownData[]
  selectedCount = 0
  numberOfItems = 0
  scrollStrategy: ScrollStrategy = this.sso.reposition()
  searchValue = ''
  showDropdown = false

  optionControl = new UntypedFormControl()
  selectedValue: string

  SHOW_SEARCHBAR_ELEMENTS_THRESHOLD = 6

  private destroy$ = new Subject<void>()

  constructor(
    private _eref: ElementRef,
    private sso: ScrollStrategyOptions
  ) {}

  ngOnInit(): void {
    this.optionControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(idSelected => {
        this.data.forEach(section => {
          section.items.forEach(item => {
            item.checked = idSelected === item.id
          })
        })
      })
  }

  //On click outside of component
  @HostListener('document:click', ['$event'])
  hideDropdown(event) {
    if (this.showDropdown && !this._eref.nativeElement.contains(event.target)) {
      this.onCloseDropdown()
    }
  }

  filterItems(value: string) {
    if (!value) {
      this.filteredData = this.data
      return
    }
    this.filteredData = []
    this.data.forEach(section => {
      const newSection = {
        title: section.title,
        items: section.items.filter(i =>
          containsNormalizedText(i.label, value)
        ),
      }
      if (newSection.items.length > 0) {
        this.filteredData.push(newSection)
      }
    })
  }

  handleDropdownMenuClick(event: Event) {
    event.stopPropagation()
  }

  onClickApply() {
    this.setInitialData(this.data)
    this.onCloseDropdown()
    this.applyChanges.emit(this.data)
  }

  onCloseDropdown() {
    this.data = cloneValue(this.initialData)
    this.filteredData = this.data
    this.searchValue = ''
    this.showDropdown = false
  }

  onChange = (value: SingleSelectDropdownData[]) => {}
  onTouched = () => {}

  registerOnChange(onChange: any): void {
    this.onChange = onChange
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched
  }

  toggleDropdown() {
    if (this.showDropdown) {
      this.onCloseDropdown()
      return
    }
    this.showDropdown = true
  }

  writeValue(data: SingleSelectDropdownData[]): void {
    this.data = data
  }

  private setInitialData(data: SingleSelectDropdownData[]) {
    this.initialData = cloneValue(data)
    this.filteredData = data
    this.setNumberOfItems()
    this.setSelectedValue()
  }

  private setNumberOfItems() {
    this.numberOfItems = 0
    this.data?.forEach(section => (this.numberOfItems += section.items.length))
  }

  private updateRadioChecked() {
    const selectedItem = this.getSelectedItem()

    this.optionControl.setValue(selectedItem?.id, { emitEvent: false })
  }

  private setSelectedValue() {
    const selectedItem = this.getSelectedItem()

    if (selectedItem) {
      this.selectedValue = selectedItem.label
    }
  }

  private getSelectedItem(): DropdownSelectableItem {
    return this.data
      .flatMap(section => section.items)
      .find(item => item.checked)
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
