enum Modules {
  APP = 'APP',
  BOTS = 'BOTS',
  CAMPAIGNS = 'CMPG',
  DESK = 'DESK',
  ORGANIZATION = 'ORG',
}

export const AppViews = {
  ORGANIZATION_PROJECTS: `[${Modules.ORGANIZATION}] Projects`,
  ORGANIZATION_SETTINGS: `[${Modules.ORGANIZATION}] Settings`,
  ORGANIZATION_TEMPLATES: `[${Modules.ORGANIZATION}] Templates`,
  ORGANIZATION_ACCOUNTS: `[${Modules.ORGANIZATION}] Accounts`,
  ORGANIZATION_WEBHOOKS: `[${Modules.ORGANIZATION}] Webhooks`,
  ORGANIZATION_CONTACT_INFO_FIELDS: `[${Modules.ORGANIZATION}] Contact-Info-Fields`,
}

export const AnalyticsEvents = {
  AUTOTRANSLATE_MODAL_NEW_LANG_SELECTED:
    'autotransalation_modal_new_language_selected',
  AUTOTRANSLATE_MODAL_NEW_LANG_CLOSE:
    'autotranslation_select_new_language_close',
  AUTOTRANSLATE_MODAL_PRIMARY_LANG_SHOWN:
    'autotranslation_primary_language_modal',
  AUTOTRANSLATE_TOGGLE_TRANSLATION_MSG:
    'autotranslation_toggle_translation_message',
  AUTOTRANSLATE_REMOVE_LANGUAGES_NEVER_TRANSLATE:
    'autotranslation_remove_language_never_translate',
  AUTOTRANSLATE_REMOVE_LANGUAGES_ALWAYS_TRANSLATE:
    'autotranslation_remove_language_always_translate',
  AUTOTRANSLATE_EDIT_PREFERENCES: 'automatization_edit_preferences_CTA',
  CASE_ARCHIVE_SEARCH: 'Case Archive Search',
  CASE_ARCHIVE_CASE_TRANSCRIPT: 'Case Archive Download Case Transcript',
  INBOX_CASE_TRANSCRIPT: 'Inbox Download Case Transcript',
  INBOX_FILTER: 'Inbox Filter Cases',
  INBOX_LOAD_MORE: 'Inbox Load More Cases',
  INBOX_FILTER_QUEUE: 'inbox_click_filter_by_queues',
  INBOX_FILTER_SORT: 'inbox_click_filter_by_sort',
  INBOX_FILTER_STATUS: 'inbox_case_status_filter',
  INBOX_FILTER_CASE: 'inbox_filter_case',
  INBOX_FILTER_SEARCH_CASE: 'inbox_search_case',
  INBOX_NEW_WHATSAPP_CASE: 'inbox_new_whatsapp_case_click',
  INBOX_QUEUE_ICON_AGENT: 'inbox_icon_agent_queue',
  INBOX_CONTACT_INFO_TOGGLE: 'chat_toggle_contact_info_panel',
  INBOX_CHAT_ADD_CONTACT_REASONS: 'inbox_chat_add_contact_reasons',
  INBOX_CHAT_REMOVE_CONTACT_REASON: 'inbox_chat_remove_contact_reason',
  NAVBAR_COLLAPSE_UNCOLLAPSE: 'navbar_collapse_uncollapse',
  CASE_SELECT_TYPIFICATION: 'Select typification',
  SCREEN_ACTION: 'Screen Action',
  SEND_ACTION: 'Send',
  TEMPLATE_ACTION: 'Template Action',
  TEMPLATE_FILTER: 'Template Filter',
  TEMPLATE_OLD_FILTER: 'Template Legacy Filter',
  TOGGLE_VIEW: 'View change',
  TRANSFER_CASE: 'Transfer Case',
  TRANSFER_CASE_CONFIRM: 'Transfer Case Confirm',
  CANCEL_TRANSFER_CASE: 'Cancel transfer',
  REPORTING_SEARCH: 'Reporting Search',
  REPORTING_HISTORY_BREAKDOWN: 'Reporting history breakdown buttons',
  CONTACT_INFO_COPY_CASE_ID: 'chat_copy_caseid',
  CONTACT_INFO_COPY_CHAT_ID: 'chat_copy_userid',
  CONTACT_INFO_COPY_FIELD_CONTENT: 'chat_copy_field_content',
  CONTACT_INFO_COPY_CHAT_NAME: 'chat_copy_chat_name',
  CONTACT_INFO_SAVE_ADDITIONAL_FIELD: 'chat_save_aditional_info_field_click',
  CONTACT_INFO_ADD_ADDITIONAL_FIELD: 'chat_add_new_field',
  ANALYTICS_DATE_RANGE_APPLY_CLICK: 'analytics_date_range_apply_click',
  ANALYTICS_COMPARE_APPLY_CLICK: 'analytics_compare_apply_click',
  ANALYTICS_EMPTY_STATE: 'analytics_empty_state',
  ANALYTICS_QUEUES_FILTERS_APPLIED: 'analytics_queues_filter_applied',
  ANALYTICS_PROJECTS_FILTER_APPLIED: 'analytics_projects_filter_applied',
  ANALYTICS_CHANNELS_FILTER_APPLIED: 'analytics_channels_filter_applied',
  ANALYTICS_REMOVE_FILTERS: 'analytics_remove_filters',
  ANALYTICS_TABLE_SEARCH: 'analytics_table_search',
  ANALYTICS_API_DATA_FAIL: 'analytics_api_data_fail',
  ANALYTICS_TOP_AGENTS_SORT_BY_FILTER: 'analytics_agents_sort_by_filter',
  ANALYTICS_AGENTS_TABE_SEARCH: 'analytics_agents_table_search',
  ANALYTICS_AGENTS_TABLE_CHEVRON_EXPAND: 'analytics_chevron_table_expand',
  ANALYTICS_TOP_AGENTS_SEARCH_AGENT: 'top_agents_search_agent',
  ANALYTICS_TOP_AGENTS_EMPTY_STATE: 'analytics_agents_empty_state',
  ANALYTICS_TOP_AGENTS_SEE_MORE_CLICK: 'analytics_agents_see_more_click',
  ANALYTICS_TOP_AGENTS_SELECT_AGENT: 'analytics_top_agents_selected_agent',
  ANALYTICS_TOP_AGENTS_UNSELECT_AGENT: 'analytics_top_agents_unselect_agent',
  ANALYTICS_AGENTS_DOWNLOAD_CSV_TABLE: 'analytics_download_csv_agent_table',
  ANALYTICS_AGENTS_STATUS_DOWNLOAD_CSV_TABLE:
    'analytics_download_csv_agent_status_table',
  ANALYTICS_PROJECTS_DOWNLOAD_CSV_TABLE: 'analytics_download_csv_project_table',
  ANALYTICS_CONVERSATIONS_DOWNLOAD_CSV_TABLE:
    'analytics_download_csv_conversations_table',
  ANALYTICS_BOTS_KEEP_ME_UPDATED: 'analytics_bots_keep_me_updated',
  ANALYTICS_AUTOMATION_LEARN_MORE: 'analytics_automation_learn_more',
  CHAT_ASSISTANT_SUGGEST: 'chat_assistant_suggest',
  CHAT_ASSISTANT_SUGGEST_ERROR: 'chat_assistant_suggest_error',
  CHAT_ASSISTANT_SUGGEST_USED: 'chat_assistant_suggest_used',
  PUSHER_CONNECTION_LOST: 'pusher_connection_lost', // This event shouldn't be able to be tracked because you are offline when triggered. Just to confrm that this is happening.
  PUSHER_CONNECTION_RECONNECTED: 'pusher_connection_reconnected',
  PUSHER_CONNECTION_RECONNECTED_REFRESH_CLICK:
    'pusher_connection_reconnected_refresh_click',
  REALTIME_MANAGE_QUEUE_AGENTS: 'clicked_manage_queue',
  MANAGE_QUEUE_AGENTS_MODAL_ASSIGN: 'assign_agents_to_queue',
  MANAGE_QUEUE_AGENTS_MODAL_UNASSIGN: 'unassign_agent_from_queue',
  CASES_DATA_UNSYNC_ERROR: 'cases_data_unsync_error',

  // Case summary
  CASE_SUMMARY_CTA_SHOWN: 'case_summary_cta_shown',
  CASE_SUMMARY_RECOVERED: 'case_summary_recovered',
  CASE_SUMMARY_RATED: 'case_summary_rated',
  CASE_SUMMARY_SKIPPED: 'case_summary_skipped',
  CASE_SUMMARY_REQUESTED: 'case_summary_requested',
  CASE_SUMMARY_COMPLETED: 'case_summary_completed',
  CASE_SUMMARY_ERROR: 'case_summary_error',
  CASE_SUMMARY_CLOSED: 'case_summary_closed',

  // Request licenses
  REQUEST_LICENSES_MODAL_OPEN: 'request_licenses_modal_open',
  LICENSES_REQUESTED: 'licenses_requested',

  // Addons
  ADDONS_MORE_INFO_MODAL_OPEN: 'addons_more_info_modal_open',
  ADDONS_REQUEST_DEMO: 'addons_request_demo',
}

const URLS_WITH_VARIABLES_BOTS = /\/bots\/([a-f0-9\-]+)\/(\w+)/
const URLS_WITH_VARIABLES_DESK = /\/desk\/(inbox|archive)\/([a-f0-9\-]+$)/
const ARCHIVE_NAME = `[${Modules.DESK}] Archive`
const INBOX_NAME = `[${Modules.DESK}] Inbox`

function getViewNameApp(url: string) {
  switch (url) {
    case '/sign-in':
      return `[${Modules.APP}] Sign in`
    case '/reset-pwd':
      return `[${Modules.APP}] Reset-Pwd`
    case '/profile':
      return `[${Modules.APP}] Profile`
    case '/complete-profile':
      return `[${Modules.APP}] Complete-Profile`
    case '/signup':
      return `[${Modules.APP}] Signup`
    default:
      return url
  }
}

function getViewNameBots(url: string) {
  if (url === '/bots/all') {
    return `[${Modules.BOTS}] All`
  }
  const result = URLS_WITH_VARIABLES_BOTS.exec(url)
  if (!result) {
    return url
  }

  const suffix = result[2]
  switch (suffix) {
    case 'integrations':
      return `[${Modules.BOTS}] Integrations`
    case 'debug':
      return `[${Modules.BOTS}] Debug`
    case 'stats':
      return `[${Modules.BOTS}] Stats`
    case 'updates':
      return `[${Modules.BOTS}] Updates`
    case 'edit':
      return `[${Modules.BOTS}] Edit`
    case 'settings':
      return `[${Modules.BOTS}] Settings`
    default:
      return url
  }
}

function getViewNameCampaigns(url: string) {
  switch (url) {
    case '/campaigns/manage':
      return `[${Modules.CAMPAIGNS}] Manage`
    case '/campaigns/contacts':
      return `[${Modules.CAMPAIGNS}] Contacts`
    case '/campaigns/metrics':
      return `[${Modules.CAMPAIGNS}] Metrics`
    default:
      return url
  }
}

function getViewNameDesk(url: string) {
  switch (url) {
    case '/desk/inbox':
      return INBOX_NAME
    case '/desk/archive':
      return ARCHIVE_NAME
    case '/desk/reporting':
      return `[${Modules.DESK}] Reporting`
    case '/desk/dashboard':
      return `[${Modules.DESK}] Dashboard`
    default:
  }
  const result = URLS_WITH_VARIABLES_DESK.exec(url)
  if (result) {
    const prefix = result[1] === 'archive' ? ARCHIVE_NAME : INBOX_NAME
    return `${prefix} Case View`
  }
  return url
}

function getViewNameOrganization(url: string) {
  switch (url) {
    case '/organization/edit':
      return `[${Modules.ORGANIZATION}] Edit`
    case '/organization/display':
      return `[${Modules.ORGANIZATION}] Display`
    case '/organization/plans':
      return `[${Modules.ORGANIZATION}] Plans`
    case '/organization/accounts':
      return `${AppViews.ORGANIZATION_ACCOUNTS}`
    case '/organization/webhooks':
      return `${AppViews.ORGANIZATION_WEBHOOKS}`
    case '/organization/projects':
      return `${AppViews.ORGANIZATION_PROJECTS}`
    case '/organization/templates':
      return `${AppViews.ORGANIZATION_TEMPLATES}`
    case '/organization/contact-info-fields':
      return `${AppViews.ORGANIZATION_CONTACT_INFO_FIELDS}`
    default:
      return url
  }
}

export function getViewNameByUrl(url: string) {
  if (url.startsWith('/bots')) {
    return getViewNameBots(url)
  } else if (url.startsWith('/campaigns')) {
    return getViewNameCampaigns(url)
  } else if (url.startsWith('/desk')) {
    return getViewNameDesk(url)
  } else if (url.startsWith('/organization')) {
    return getViewNameOrganization(url)
  }
  return getViewNameApp(url)
}

export function dataFromPathVariables(
  url: string
): { [key: string]: string | number } | null {
  if (!url) {
    return null
  } else if (url.startsWith('/bots')) {
    const result = URLS_WITH_VARIABLES_BOTS.exec(url)
    return result && result[1] && { botId: result[1] }
  } else if (url.startsWith('/desk')) {
    const result = URLS_WITH_VARIABLES_DESK.exec(url)
    return result && result[2] && { caseId: result[2] }
  }
  return null
}
