import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  HtButtonDSAppearance,
  HtButtonDSIntent,
} from '../ht-button-ds/ht-button-ds.component'

export interface HtModalOptionsBtn {
  show: boolean
  text?: string
  intent?: HtButtonDSIntent
  appearance?: HtButtonDSAppearance
  disabled?: boolean
  click?: any
  role?: string
  isLoading?: boolean
  /**
   * To right align -> margin-left: auto
   * To left align -> leave it blank
   */
  style?: string
}
export interface HtModalOptions {
  notClosable?: boolean
  leftBtn?: HtModalOptionsBtn
  rightBtnArray?: HtModalOptionsBtn[]
  title?: {
    text: string
    color?: 'default' | 'red'
  }
  titleIconClass?: string
  width?: number
  size?: 'small'
}

@Component({
  selector: 'ht-modal',
  templateUrl: './ht-modal.component.html',
  styleUrls: ['./ht-modal.component.scss'],
  host: {
    role: 'dialog',
    '[attr.aria-label]': 'options.title?.text',
  },
})
export class HtModalComponent implements OnChanges {
  @Input()
  data: any = {}

  @Input()
  options: HtModalOptions = {
    leftBtn: { show: false },
    rightBtnArray: [],
  }

  @Output()
  closeModalOutput = new EventEmitter<boolean>()

  isOpen = false
  hostElement: any

  constructor(
    private elRef: ElementRef,
    private ref: ChangeDetectorRef
  ) {
    this.hostElement = this.elRef.nativeElement
  }

  @HostBinding(`class.--size-small`) get smallClass() {
    return !this.options.width && this.options.size === 'small'
  }
  @HostBinding('class.--size-custom') get customSizeClass() {
    return !!this.options.width
  }
  @HostBinding('class.--open') get openClass() {
    return this.isOpen
  }
  @HostBinding('class.--closed') get closedClass() {
    return !this.isOpen
  }

  @HostListener('document:keydown.escape')
  handleEscapeKey() {
    if (this.isOpen && !this.options.notClosable) {
      this.onClose()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.fillButtonRoles()
    }
  }

  getData(): any {
    return this.data
  }

  setData(data) {
    this.data = data
  }

  // Using this function knowing that is going to be executed several times. Is the less painfull way to hide the bar when an input change.
  shouldShowButtonBar() {
    return (
      this.options?.leftBtn?.show ||
      this.options?.rightBtnArray?.some(x => x.show)
    )
  }

  open(data?: any) {
    if (data) {
      this.setData(data)
    }
    this.isOpen = true
  }

  close(emitEvent: boolean = true) {
    this.isOpen = false
    // TODO: investigate why this is necessary. We are forcing the change detection because sometimes, the component doesn't trigger it.
    this.ref.markForCheck()
    if (emitEvent) {
      this.closeModalOutput.emit(false) // false indicates programmatic close
    }
  }

  onClose() {
    this.isOpen = false
    // TODO: investigate why this is necessary. We are forcing the change detection because sometimes, the component doesn't trigger it.
    this.ref.markForCheck()
    this.closeModalOutput.emit(true) // true indicates user-initiated close
  }

  private fillButtonRoles() {
    const leftBtnPresent = !!this.options.leftBtn
    const rightBtnArrayLength = this.options.rightBtnArray?.length || 0

    if (leftBtnPresent) {
      this.options.leftBtn.role = 'leftButton'
    }

    if (this.options.rightBtnArray) {
      this.options.rightBtnArray.forEach((btn, index) => {
        if (!leftBtnPresent && rightBtnArrayLength > 1 && index === 0) {
          // Only first button should be leftBtn if there's no left button
          btn.role = 'leftBtn'
        } else if (index === rightBtnArrayLength - 1) {
          // Only last button should be rightBtn
          btn.role = 'rightBtn'
        }
      })
    }
  }
}
