<ng-container *loading="data === null || data === undefined">
  <div [tooltip]="data.tooltip" class="title">
    {{ data.title }}
    <i *ngIf="data.tooltip" class="fa-solid fa-circle-info"></i>
  </div>
  <div class="info">
    <div class="text-body bold">
      <ng-container *ngIf="data.value === undefined || data.value === null"
        >--</ng-container
      >
      <ng-container *ngIf="data.value !== undefined && data.value !== null">
        <ht-metric-chip-value
          [value]="data.value"
          [type]="data.type"
        ></ht-metric-chip-value>
      </ng-container>
      <ng-container *ngIf="data.total !== undefined && data.total !== null">
        <span>/</span>
        <ht-metric-chip-value
          [value]="data.total"
          [type]="data.type"
        ></ht-metric-chip-value>
      </ng-container>
    </div>
    <div
      class="comparison"
      *ngIf="data.comparison !== undefined && data.comparison !== null"
      [ngClass]="{
        positive: data.isFavorable,
        negative: !data.isFavorable,
        neutral: !data.comparison
      }"
    >
      {{ data.comparison | percentageWithSign }} {{ data.comparisonText }}
    </div>
  </div>
</ng-container>
