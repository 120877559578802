import moment, { Moment, unitOfTime } from 'moment'
import { isObject } from './json-utils'

export const DEFAULT_DATE_AND_HOUR_FORMAT = `dd/MM/yyy 'at' HH:mm`
export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'
export const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

declare global {
  interface Date {
    subtract(amount, units): Date
    isBetweenInclusive(start, end): boolean
    removeTime(): Date
    removeMilliseconds(): Date
  }
}

// eslint-disable-next-line space-before-function-paren
Date.prototype.subtract = function (amount, units) {
  return moment(this).subtract(amount, units).toDate()
}
// eslint-disable-next-line space-before-function-paren
Date.prototype.isBetweenInclusive = function (start, end) {
  return moment(this).isBetween(start, end, null, '[]')
}
// eslint-disable-next-line space-before-function-paren
Date.prototype.removeTime = function () {
  return moment(this).startOf('day').toDate()
}
// eslint-disable-next-line space-before-function-paren
Date.prototype.removeMilliseconds = function () {
  return moment(this).milliseconds(0).toDate()
}

export function addTimeDelta(
  datetime: Moment | Date | number | string,
  delta: number,
  unit: moment.unitOfTime.Base = 'hours'
): Date {
  return moment(datetime).add(delta, unit).toDate()
}

// Timestamp in seconds
export function currentTimestamp(): number {
  return moment().unix()
}

// Timestamp in millis
export function currentMillis(): number {
  return new Date().getTime()
}

export function currentTimeString(): string {
  return moment().format()
}

export function getCurrentYear(): number {
  return new Date().getFullYear()
}

export function getCurrentMonth(): number {
  return new Date().getMonth() + 1
}

export function format(
  datetime: Moment | Date | number | string,
  pattern: string = DEFAULT_DATE_FORMAT
): string {
  let timestamp = datetime
  if (datetime instanceof Date) {
    timestamp = datetime.getTime()
  }
  return moment(timestamp).format(pattern)
}

export function formattedTimezone(timezone) {
  return timezone + ' ' + moment.tz(timezone).format('z (Z)')
}

export function getDuration(
  from: number | string | Moment,
  to?: number | string | Moment
): moment.Duration {
  const fromMoment = moment(from)
  const toMoment = to ? moment(to) : moment()

  if (!moment.isMoment(fromMoment)) {
    throw Error('Could not process the given `from` parameter')
  } else if (!moment.isMoment(toMoment)) {
    throw Error('Could not process the given `to` parameter')
  }

  return moment.duration(toMoment.diff(fromMoment))
}

export function getMonths(short: boolean = false): string[] {
  return short ? moment.monthsShort() : moment.months()
}

export function getTimeAgo(
  timestamp: number,
  unit: moment.unitOfTime.Diff = 'hours'
): number {
  const timeMoment = moment.utc(timestamp)
  if (!moment.isMoment(timeMoment)) {
    throw Error('Could not process the given timestamp')
  }
  return moment().utc().diff(timeMoment, unit, true)
}

export function isAfter(target: Date, reference: Date): boolean {
  return moment(target).isAfter(reference)
}

export function isBefore(target: Date, reference: Date): boolean {
  return moment(target).isBefore(reference)
}

export function isDate(value: unknown): boolean {
  if (!value) {
    return false
  } else if (!isObject(value)) {
    return false
  }
  return Object.prototype.toString.call(value) === '[object Date]'
}

export function parse(datetime: Moment | Date | number | string): Date {
  return moment(datetime).toDate()
}

export const startOf = (
  timestamp: number | Moment | Date | string,
  unit: unitOfTime.StartOf
) => moment(timestamp).startOf(unit).valueOf()

export const endOf = (timestamp: number | string, unit: unitOfTime.StartOf) =>
  moment(timestamp).endOf(unit).valueOf()

// TODO: Remove or rewrite to use startOf, endOf
export const toStartOfDay = (timestamp: number) =>
  moment(timestamp).startOf('day').valueOf()

export const toEndOfDay = (timestamp: number) =>
  moment(timestamp).endOf('day').valueOf()

export function numberToDate(time) {
  const date = new Date(time)
  return date // Wed Jan 12 2011 12:42:46 GMT-0800 (PST)
}

export function startOfToday() {
  return toStartOfDay(new Date().getTime())
}

export function endOfToday() {
  return toEndOfDay(new Date().getTime())
}

export function parseDuration(value: string): moment.Duration {
  return moment.duration(value)
}

export function startOfYesterday() {
  return toStartOfDay(moment().subtract(1, 'days').valueOf())
}

export function startOfYesterdayDate(): Date {
  return new Date(startOfYesterday())
}

export function endOfTodayDate(): Date {
  return new Date(endOfToday())
}

export function endOfDay(date: Date): Date {
  return moment(date).endOf('day').utc().toDate()
}

export function timestampAgo(
  value: number,
  unit: moment.unitOfTime.Diff = 'hours',
  roundAt?: moment.unitOfTime.StartOf
): number {
  let time = moment().subtract(value, unit)
  if (roundAt) {
    time = time.startOf(roundAt)
  }
  return time.valueOf()
}

export function getDifferenceInDays(start: number, end: number): number {
  const differenceInTime = end - start
  // round because when there is a time change days may not be a integer
  return Math.round(differenceInTime / (1000 * 3600 * 24))
}

export function secondsToDuration(value: number) {
  if (value === null) {
    return '--'
  }
  const hours = addZeroPrefix(Math.floor(value / 3600)) || '00'
  const timeLeft = Math.floor(value % 3600)
  const minutes = addZeroPrefix(Math.floor(timeLeft / 60))
  const seconds = addZeroPrefix(Math.floor(timeLeft % 60))
  return `${hours}:${minutes}:${seconds}`
}

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function formatDate(date: Date): string {
  return format(date, 'YYYY-MM-DD')
}

function subtractDaysFromDate(date: Date, days: number): Date {
  return date.subtract(days, 'days')
}

export function buildDateRange(
  todayDate: Date,
  startSubtract: number,
  endSubtract: number
): [string, string] {
  const startDate = formatDate(subtractDaysFromDate(todayDate, startSubtract))
  const endDate = formatDate(subtractDaysFromDate(todayDate, endSubtract))
  return [startDate, endDate]
}

function addZeroPrefix(num: number): string {
  return num.toString().padStart(2, '0')
}

export function getMonthNumberFromDate(date: Date | string): number | null {
  if (isDate(date)) {
    return (date as Date).getMonth() + 1
  }
  if (typeof date === 'string') {
    return new Date(date as string).getMonth() + 1
  }
  return null
}

export function dateToStringWithLongMonth(date: Date) {
  return date.toLocaleString('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

export function getSundayOfWeek(date: Date): Date {
  return moment(date).isoWeekday(7).toDate()
}

export function getLastDayOfMonth(date: Date): Date {
  return moment(date).endOf('month').toDate()
}

export function getMillisecondsPlusTimezone(date: Date): number {
  let timezoneOffsetMinutes = date.getTimezoneOffset()
  let timezoneOffsetMillis = timezoneOffsetMinutes * 60 * 1000

  return date.getTime() - timezoneOffsetMillis
}

export function isoWeekToDate(weekString: string): Date {
  // weekString format: "2024W1-1" or "2024W01-1" where last digit is day of week (1=Monday, 7=Sunday)
  if (!/^\d{4}W\d{1,2}-[1-7]$/.test(weekString)) {
    throw new Error(
      'Invalid week string format. Expected "YYYYWw-D" or "YYYYWww-D" (example: "2024W1-1" or "2024W01-1")'
    )
  }

  const year = parseInt(weekString.substring(0, 4))
  const wPosition = weekString.indexOf('W')
  const dashPosition = weekString.indexOf('-')
  const week = parseInt(weekString.substring(wPosition + 1, dashPosition))
  const dayOfWeek = parseInt(weekString.substring(dashPosition + 1)) - 1 // Convert to 0-based (0=Monday, 6=Sunday)

  // Validate ranges
  if (week < 1 || week > 53) {
    throw new Error('Week number must be between 1 and 53')
  }

  // Create a date for January 1st of the given year
  const jan1st = new Date(Date.UTC(year, 0, 1))
  const jan1stDay = jan1st.getUTCDay()

  // Find Monday of the week containing January 1st
  const week1Monday = new Date(jan1st)
  week1Monday.setUTCDate(
    jan1st.getUTCDate() - (jan1stDay === 0 ? 6 : jan1stDay - 1)
  )

  // Add weeks to get to the target week and then add days to get to the target day
  const targetDate = new Date(week1Monday)
  targetDate.setUTCDate(week1Monday.getUTCDate() + (week - 1) * 7 + dayOfWeek)

  return targetDate
}

export function isoQuarterToDate(quarterString: string): Date {
  // quarterString format: "2024Q1"
  const year = parseInt(quarterString.substring(0, 4))
  const quarter = parseInt(quarterString.substring(5))
  const startDate = new Date(year, (quarter - 1) * 3, 1)
  return startDate
}

export function addMillisecondsToDate(date: Date, milliseconds: number): Date {
  return new Date(date.getTime() + milliseconds)
}

export interface VacationRange {
  id: number // timestamp of the moment of creation
  start_date: number
  end_date: number
}

export interface DisplayHoliday {
  range: FormattedVacationRange
  confirmAddHoliday: boolean
  confirmDeleteHoliday: boolean
  canEdit: boolean
  isPastHoliday: boolean
}

export interface FormattedVacationRange {
  id: number
  startDate: any
  endDate: any
}
