<div class="modal" *ngIf="isOpen">
  <div [ngStyle]="{ width: options.width + 'px' }" class="modal-container">
    <div class="title">
      <div>
        <i
          *ngIf="options.titleIconClass"
          class="modal-title-icon"
          [ngClass]="options.titleIconClass"
        ></i>
        <span
          *ngIf="options.title"
          [ngClass]="{
            'title-red': options.title.color === 'red'
          }"
          class="text-body bold break-word"
        >
          {{ options.title.text }}
        </span>
      </div>

      <button
        ht-icon-button
        *ngIf="!options.notClosable"
        (click)="onClose()"
        aria-label="Close modal"
      >
        <i class="modal-close fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>

    <div *ngIf="shouldShowButtonBar()" class="button-bar">
      <button
        *ngIf="options.leftBtn as leftBtn"
        ht-button-ds
        role="leftBtn"
        [hidden]="!leftBtn.show"
        (click)="leftBtn.click()"
        [intent]="leftBtn.intent"
        [appearance]="leftBtn.appearance"
        >{{ leftBtn.text }}</button
      >
      <div class="right-buttons">
        <ng-container *ngFor="let btn of this.options.rightBtnArray">
          <button
            ht-button-ds
            [appearance]="btn.appearance"
            [intent]="btn.intent"
            [hidden]="!btn.show"
            (click)="btn.click()"
            [isDisabled]="btn.disabled || false"
            [style]="btn.style"
            [role]="btn.role"
            [isLoading]="btn.isLoading"
          >
            {{ btn.text }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isOpen" class="modal-container-background"></div>
