import { Component, HostBinding, Input, type OnInit } from '@angular/core'

export interface MetricChip {
  title: string
  type: MetricChipType
  value: number
  comparedValue?: number
  comparison?: number
  isFavorable?: boolean
  comparisonText?: string
  total?: number
  tooltip?: string
}

export enum MetricChipType {
  NUMBER = 'number',
  BIGNUMBER = 'bigNumber',
  PERCENT = 'percent',
  TIME = 'time',
  DECIMALPERCENT = 'decimalPercent',
}

@Component({
  selector: 'ht-metric-chip',
  templateUrl: './ht-metric-chip.component.html',
  styleUrls: ['./ht-metric-chip.component.scss'],
})
export class HtMetricChipComponent implements OnInit {
  @Input() data: MetricChip

  @HostBinding('attr.aria-label') get label() {
    return this.data?.title
  }

  ngOnInit(): void {}
}
