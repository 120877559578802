import { FocusMonitor } from '@angular/cdk/a11y'
import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core'
import { ControlValueAccessor, NG_VALIDATORS, NgControl } from '@angular/forms'
import {
  CountryCode,
  Examples,
} from 'lib/ngx-mat-intl-tel-input/data/country-code'
import {
  PhoneNumber,
  getExampleNumber,
  parsePhoneNumberFromString,
} from 'libphonenumber-js'
import { Subject } from 'rxjs'
import { phoneNumberValidator } from 'src/app/validators/phone-number.validator'

export interface Country {
  name: string
  iso2: string
  dialCode: string
  priority: number
  areaCodes?: string[]
  flagClass: string
  placeHolder: string
}

@Component({
  selector: 'ht-phone-input',
  templateUrl: './ht-phone-input.component.html',
  styleUrls: ['./ht-phone-input.component.scss'],
  providers: [
    CountryCode,
    { provide: NG_VALIDATORS, useValue: phoneNumberValidator, multi: true },
  ],
})
export class HtPhoneInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy, DoCheck
{
  @Input() placeholder = ''
  @Input() width = ''
  @Input() disabled = false
  @Input() preferredCountries: Array<string> = []
  @Input() onlyCountries: Array<string> = []
  @Input() enablePlaceholder = true
  @Input() label?: string

  @Output()
  countryChanged: EventEmitter<Country> = new EventEmitter<Country>()

  phoneNumber = ''
  allCountries: Array<Country> = []
  filteredCountries: Array<Country> = []
  preferredCountriesInDropDown: Array<Country> = []
  selectedCountry: Country
  numberInstance: PhoneNumber
  isDropdownOpen = false

  value = ''
  searchValue = ''

  stateChanges = new Subject<void>()
  focused = false
  errorState = false

  constructor(
    private countryCodeData: CountryCode,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Self() @Optional() private control: NgControl
  ) {
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched()
      }
      this.focused = !!origin
      this.stateChanges.next()
    })
    this.fetchCountryData()
    if (this.control) {
      this.control.valueAccessor = this
    }
  }

  static getPhoneNumberPlaceHolder(countryISOCode: any): string {
    try {
      return getExampleNumber(countryISOCode, Examples).number.toString()
    } catch (e) {
      return e
    }
  }

  //On click outside of component
  @HostListener('document:click', ['$event'])
  hideDropdown(event) {
    if (
      this.isDropdownOpen &&
      !this.elRef.nativeElement.contains(event.target)
    ) {
      this.isDropdownOpen = false
    }
  }

  onChange = value => {}
  onTouched = () => {}

  onInput(value: string) {
    this.value = value
    this.onTouched()
    this.onChange(this.value)
  }

  writeValue(value: string): void {
    // when form is reset
    if (value === null) {
      this.reset()
    }
    if (value) {
      this.numberInstance = parsePhoneNumberFromString(value)
      if (this.numberInstance) {
        const countryCode = this.numberInstance.country
        this.phoneNumber = this.numberInstance.formatNational()
        if (!countryCode) {
          return
        }
        setTimeout(() => {
          this.selectedCountry = this.allCountries.find(
            c => c.iso2 === countryCode.toLowerCase()
          )
          this.countryChanged.emit(this.selectedCountry)
        }, 1)
      }
    }
  }
  registerOnChange(onChange: any) {
    this.onChange = onChange
  }
  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  ngOnInit() {
    if (this.preferredCountries.length) {
      this.preferredCountries.forEach(iso2 => {
        const preferredCountry = this.allCountries.filter(c => c.iso2 === iso2)
        this.preferredCountriesInDropDown.push(preferredCountry[0])
      })
    }
    if (this.onlyCountries.length) {
      this.allCountries = this.allCountries.filter(c =>
        this.onlyCountries.includes(c.iso2)
      )
    }
    if (this.numberInstance && this.numberInstance.country) {
      // If an existing number is present, we use it to determine selectedCountry
      this.selectedCountry = this.allCountries.find(
        c => c.iso2 === this.numberInstance.country.toLowerCase()
      )
    } else {
      if (this.preferredCountriesInDropDown.length) {
        this.selectedCountry = this.preferredCountriesInDropDown[0]
      } else {
        this.selectedCountry = this.allCountries[0]
      }
    }
    this.filteredCountries = this.allCountries
    this.countryChanged.emit(this.selectedCountry)
  }

  ngOnDestroy() {
    this.stateChanges.complete()
    this.fm.stopMonitoring(this.elRef.nativeElement)
  }

  ngDoCheck(): void {
    if (this.control) {
      this.errorState = this.control.invalid && this.control.touched
      this.stateChanges.next()
    }
  }

  reset() {
    this.phoneNumber = ''
    this.onChange(null)
  }

  toggleDropdown(evt) {
    evt.stopPropagation()
    this.isDropdownOpen = !this.isDropdownOpen
  }

  onSearch(value: string) {
    if (!value) {
      this.filteredCountries = this.allCountries.filter(
        c => !this.preferredCountries.includes(c.name)
      )
      return
    }
    this.filteredCountries = this.allCountries.filter(c =>
      c.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    )
  }

  public onPhoneNumberChange(): void {
    try {
      this.numberInstance = parsePhoneNumberFromString(this.getFullNumber())
      this.value = this.numberInstance.number
      if (this.numberInstance && this.numberInstance.isValid()) {
        this.phoneNumber = this.numberInstance.formatNational()
      }
    } catch (e) {
      // if no possible numbers are there,
      // then the full number is passed so that validator could be triggered and proper error could be shown
      this.value = this.getFullNumber()
    }
    this.onChange(this.value)
  }

  public onCountrySelect(country: Country): void {
    this.selectedCountry = country
    this.countryChanged.emit(this.selectedCountry)
    this.onPhoneNumberChange()
    this.isDropdownOpen = false
  }

  public onInputKeyPress(event): void {
    const pattern = /[0-9+\- ]/
    if (!pattern.test(event.key)) {
      event.preventDefault()
    }
  }

  protected fetchCountryData(): void {
    this.countryCodeData.allCountries.forEach(c => {
      const country: Country = {
        name: c[0].toString(),
        iso2: c[1].toString(),
        dialCode: c[2].toString(),
        priority: +c[3] || 0,
        areaCodes: (c[4] as string[]) || undefined,
        flagClass: c[1].toString().toUpperCase(),
        placeHolder: '',
      }
      if (this.enablePlaceholder) {
        country.placeHolder = HtPhoneInputComponent.getPhoneNumberPlaceHolder(
          country.iso2.toUpperCase()
        )
      }
      this.allCountries.push(country)
    })
  }

  private getFullNumber() {
    const val = this.phoneNumber.trim()
    const dialCode = this.selectedCountry.dialCode
    let prefix
    const numericVal = val.replace(/\D/g, '')
    // normalized means ensure starts with a 1, so we can match against the full dial code
    const normalizedVal =
      numericVal.charAt(0) === '1' ? numericVal : '1'.concat(numericVal)
    if (val.charAt(0) !== '+') {
      // when using separateDialCode, it is visible so is effectively part of the typed number
      prefix = '+'.concat(dialCode)
    } else if (
      val &&
      val.charAt(0) !== '+' &&
      val.charAt(0) !== '1' &&
      dialCode &&
      dialCode.charAt(0) === '1' &&
      dialCode.length === 4 &&
      dialCode !== normalizedVal.substr(0, 4)
    ) {
      // ensure national NANP numbers contain the area code
      prefix = dialCode.substr(1)
    } else {
      prefix = ''
    }
    return prefix + numericVal
  }
}
