/* eslint-disable @typescript-eslint/naming-convention */
import { Any, JsonObject, JsonProperty } from 'json2typescript'
import { FeatureFlag } from '../constants/feature-flags'
import { HubtypeOrganization } from './hubtype-organization'
import { HubtypeUser } from './hubtype-user'

export enum HubtypeProviders {
  APPLE = 'apple',
  FACEBOOK = 'facebook',
  GENERIC = 'generic',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  TWITTER = 'twitter',
  WEBCHAT = 'webchat',
  WHATSAPP = 'whatsapp',
  WHATSAPP_CLOUD = 'whatsapp_cloud',
  WHATSAPP_PLAYGROUND = 'whatsapp_playground',
}

@JsonObject
export class HubtypeProviderAccount {
  public static APPLE = HubtypeProviders.APPLE
  public static FACEBOOK = HubtypeProviders.FACEBOOK
  public static GENERIC = HubtypeProviders.GENERIC
  public static INSTAGRAM = HubtypeProviders.INSTAGRAM
  public static TELEGRAM = HubtypeProviders.TELEGRAM
  public static TWITTER = HubtypeProviders.TWITTER
  public static WEBCHAT = HubtypeProviders.WEBCHAT
  public static WHATSAPP = HubtypeProviders.WHATSAPP
  public static WHATSAPP_CLOUD = HubtypeProviders.WHATSAPP_CLOUD
  public static WHATSAPP_PLAYGROUND = HubtypeProviders.WHATSAPP_PLAYGROUND

  public static PROVIDERS_WITH_ICON: string[] = [
    HubtypeProviders.APPLE,
    HubtypeProviders.FACEBOOK,
    HubtypeProviders.INSTAGRAM,
    HubtypeProviders.TELEGRAM,
    HubtypeProviders.WHATSAPP,
  ]

  public static names: Record<HubtypeProviders, string> = {
    [HubtypeProviders.APPLE]: 'Apple Messages',
    [HubtypeProviders.FACEBOOK]: 'Facebook Messenger',
    [HubtypeProviders.GENERIC]: 'Web / In-app Chat',
    [HubtypeProviders.INSTAGRAM]: 'Instagram',
    [HubtypeProviders.TELEGRAM]: 'Telegram',
    [HubtypeProviders.TWITTER]: 'Twitter',
    [HubtypeProviders.WEBCHAT]: 'Webchat',
    [HubtypeProviders.WHATSAPP]: 'WhatsApp',
    [HubtypeProviders.WHATSAPP_CLOUD]: 'WhatsApp Cloud',
    [HubtypeProviders.WHATSAPP_PLAYGROUND]: 'WhatsApp Playground',
  }

  public static ALL_PROVIDERS = [
    // the list of providers for the chats/messages
    // it must be the same that manages the back end (at improviders and provider's field in models)
    HubtypeProviders.APPLE,
    HubtypeProviders.FACEBOOK,
    HubtypeProviders.GENERIC,
    HubtypeProviders.INSTAGRAM,
    HubtypeProviders.TELEGRAM,
    HubtypeProviders.TWITTER,
    HubtypeProviders.WEBCHAT,
    HubtypeProviders.WHATSAPP,
  ]

  //org.hasAccessTo
  public static BOT_PROVIDERS = [
    HubtypeProviders.APPLE,
    HubtypeProviders.FACEBOOK,
    HubtypeProviders.INSTAGRAM,
    HubtypeProviders.TELEGRAM,
    HubtypeProviders.TWITTER,
    HubtypeProviders.WEBCHAT,
    HubtypeProviders.WHATSAPP_CLOUD,
    HubtypeProviders.WHATSAPP_PLAYGROUND,
  ]

  public static QUEUE_PROVIDERS = [
    HubtypeProviders.TELEGRAM,
    HubtypeProviders.FACEBOOK,
    HubtypeProviders.TWITTER,
  ]

  public static messageCharLimits: any = {
    [HubtypeProviders.APPLE]: 5000, // Just guessing
    [HubtypeProviders.FACEBOOK]: 2000, // https://developers.facebook.com/docs/messenger-platform/reference/send-api#request
    [HubtypeProviders.GENERIC]: 1000, // Just guessing
    [HubtypeProviders.INSTAGRAM]: 500, // 1000 bytes, UTF-8 (1-4 bytes per character) so aprox 500 chars https://developers.facebook.com/docs/messenger-platform/instagram/features/send-message#supported-messages
    [HubtypeProviders.TELEGRAM]: 4096, // https://core.telegram.org/method/messages.sendMessage
    [HubtypeProviders.TWITTER]: 10000, // Google search
    [HubtypeProviders.WEBCHAT]: 5000, // Just guessing
    [HubtypeProviders.WHATSAPP]: 4096, // https://developers.facebook.com/docs/whatsapp/api/messages/text#request
  }

  @JsonProperty('id', String, true)
  public id?: string = undefined
  @JsonProperty('name', String, true)
  public name?: string = undefined
  @JsonProperty('username', String, true)
  public username?: string = undefined
  @JsonProperty('phone_number', String, true)
  public phone_number?: string = undefined
  @JsonProperty('credentials', String, true)
  public credentials?: string = undefined
  @JsonProperty('imp_id', String, true)
  public imp_id?: string = undefined
  @JsonProperty('credentials_json', Any, true)
  public credentials_json?: any = undefined
  @JsonProperty('provider', String, true)
  public provider?: string = undefined
  @JsonProperty('created_by', Any, true)
  public created_by?: HubtypeUser = undefined
  @JsonProperty('queue_id', String, true)
  public queue_id?: string = undefined
  @JsonProperty('bot_id', String, true)
  public bot_id?: string = undefined
  @JsonProperty('getStarted', Boolean, true)
  public getStarted?: boolean = undefined
  @JsonProperty('chat_count', Number, true)
  public chat_count?: number = undefined
  @JsonProperty('is_active', Boolean, true)
  public is_active?: boolean = undefined
  @JsonProperty('netlify_url', String, true)
  public netlify_url?: string = undefined
  @JsonProperty('is_playground', Boolean, true)
  public is_playground?: boolean = undefined
  @JsonProperty('is_whatsapp_cloud', Boolean, true)
  public is_whatsapp_cloud?: boolean = undefined

  constructor() {
    // this.have_settings = (
    //   this.provider == PROVIDERS.WEBCHAT
    // )
  }

  public get apple(): string {
    return HubtypeProviders.APPLE
  }

  public get facebook(): string {
    return HubtypeProviders.FACEBOOK
  }

  public get generic(): string {
    return HubtypeProviders.GENERIC
  }

  public get instagram(): string {
    return HubtypeProviders.INSTAGRAM
  }

  public get telegram(): string {
    return HubtypeProviders.TELEGRAM
  }

  public get twitter(): string {
    return HubtypeProviders.TWITTER
  }

  public get webchat(): string {
    return HubtypeProviders.WEBCHAT
  }

  public get whatsapp(): string {
    return HubtypeProviders.WHATSAPP
  }

  public get isApple(): boolean {
    return this.provider === HubtypeProviders.APPLE
  }

  public get isFacebook(): boolean {
    return this.provider === HubtypeProviders.FACEBOOK
  }

  public get isGeneric(): boolean {
    return this.provider === HubtypeProviders.GENERIC
  }

  public get isInstagram(): boolean {
    return this.provider === HubtypeProviders.INSTAGRAM
  }

  public get isTelegram(): boolean {
    return this.provider === HubtypeProviders.TELEGRAM
  }

  public get isTwitter(): boolean {
    return this.provider === HubtypeProviders.TWITTER
  }

  public get isWebchat(): boolean {
    return this.provider === HubtypeProviders.WEBCHAT
  }

  public get isWhatsapp(): boolean {
    return this.provider === HubtypeProviders.WHATSAPP
  }

  public get channelUrl(): string {
    if (this.isFacebook) {
      return `https://m.me/${this.username}`
    } else if (this.isInstagram) {
      return `https://www.instagram.com/${this.username}/`
    } else if (this.isTelegram) {
      return `https://t.me/${this.name}`
    } else if (this.isTwitter) {
      return `https://twitter.com/${this.username}`
    } else if (this.is_whatsapp_cloud) {
      return `https://wa.me/${this.phone_number.replace(/[\+ \-]/g, '')}`
    } else if (this.isWhatsapp) {
      return `https://wa.me/${this.username}`
    }
  }

  public get pic(): string {
    if (this.isApple) {
      return `/assets/images/providers/apple@3x.svg`
    }
    if (this.isFacebook) {
      return `/assets/images/providers/fb-messenger@3x.png`
    }
    if (this.isGeneric) {
      return `/assets/images/providers/generic@3x.png`
    }
    if (this.isInstagram) {
      return `/assets/images/providers/instagram@3x.png`
    }
    if (this.isTelegram) {
      return `/assets/images/providers/telegram@3x.png`
    }
    if (this.isTwitter) {
      return `/assets/images/providers/twitter@3x.png`
    }
    if (this.isWebchat) {
      return `/assets/images/providers/botonic@3x.png`
    }
    if (this.isWhatsapp) {
      return `/assets/images/providers/whatsapp@3x.png`
    }
  }
}

export interface WebchatSettings {
  visible: boolean
  scheduled_queue_id?: string
  whitelisted_urls?: string[]
}

export interface WhatsAppSettings {
  about?: string
  address?: string
  description?: string
  email?: string
  vertical?: string
  websites?: string
}

@JsonObject
export class WhatsappPhoneNumber {
  @JsonProperty('id', String, true)
  public id: string = undefined
  @JsonProperty('waba_id', String, true)
  public waba_id: string = undefined
  @JsonProperty('display_phone_name', String, true)
  public display_phone_name: string = undefined
  @JsonProperty('display_phone_number', String, true)
  public display_phone_number: string = undefined
}

@JsonObject
export class WhatsappPhoneNumberStatus {
  @JsonProperty('id', String, true)
  public id: string = undefined
  @JsonProperty('waba_id', String, true)
  public waba_id: string = undefined
  @JsonProperty('verified_name', String, true)
  public verified_name: string = undefined
  @JsonProperty('display_phone_number', String, true)
  public display_phone_number: string = undefined
  @JsonProperty('name_status', String, true)
  public name_status: string = undefined // APPROVED, PENDING_REVIEW, DECLINED, AVAILABLE_WITHOUT_REVIEW
  @JsonProperty('new_name_status', String, true)
  public new_name_status?: string = undefined // '', APPROVED, PENDING_REVIEW, DECLINED, AVAILABLE_WITHOUT_REVIEW
  @JsonProperty('is_verified', Boolean, true)
  public is_verified: boolean = undefined
  @JsonProperty('has_certificate', Boolean, true)
  public has_certificate?: boolean = undefined
  @JsonProperty('quality_rating', String, true)
  public quality_rating: string = undefined
  @JsonProperty('account_mode', String, true)
  public account_mode: string = undefined // LIVE, SANDBOX
}

export function filterEnabledProviders(
  providers: string[],
  organization: HubtypeOrganization
): string[] {
  return providers.filter(provider => {
    // It checks hasAccessTo exists only when the use refresh the add provider page which generates a first execution where the organization object isn't still available
    switch (provider) {
      case HubtypeProviders.APPLE:
        return (
          organization?.hasAccessTo &&
          organization?.hasAccessTo(FeatureFlag.APPLE_PROVIDER_ENABLED)
        )
      case HubtypeProviders.INSTAGRAM:
        return (
          organization?.hasAccessTo &&
          organization?.hasAccessTo(FeatureFlag.INSTAGRAM_PROVIDER_ENABLED)
        )
      case HubtypeProviders.WHATSAPP_CLOUD:
        return organization.hasNonFreeContractType()
      default:
        return true
    }
  })
}
