import { Clipboard } from '@angular/cdk/clipboard'
import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core'
import { DEFAULT_DATE_AND_HOUR_FORMAT } from 'utils/time-utils'
import { Column, TableColumnInterface, TableRow } from '../ht-table.component'
import { TableColumnDirective } from './table-column-directive'

@Component({
  selector: '[table-column]',
  templateUrl: './table-column.component.html',
  styleUrls: ['./table-column.component.scss'],
  host: {
    role: 'cell',
  },
})
export class TableColumnComponent implements OnInit, OnChanges {
  @ViewChild(TableColumnDirective, { static: true })
  columnComponent!: TableColumnDirective

  @Input() column: Column
  @Input() row: TableRow
  @Input() isEditing: boolean

  label: string

  readonly DEFAULT_DATE_AND_HOUR_FORMAT = DEFAULT_DATE_AND_HOUR_FORMAT

  constructor(private clipboard: Clipboard) {}

  ngOnInit(): void {
    this.createCustomComponent()
    this.updateLabel()
  }

  ngOnChanges() {
    this.updateLabel()
  }

  copyToClipboard(event: Event, content: string) {
    event.stopPropagation()
    this.clipboard.copy(content)
  }

  private updateLabel() {
    if (this.row) {
      if (typeof this.column.computedLabel === 'function') {
        this.label = this.column.computedLabel(this.row.value)
      } else {
        this.label = this.row.value[this.column.key]
      }
    }
  }

  private createCustomComponent() {
    if (this.column.component) {
      const viewContainerRef = this.columnComponent.viewContainerRef
      viewContainerRef.clear()

      const componentRef =
        viewContainerRef.createComponent<TableColumnInterface>(
          this.column.component
        )
      componentRef.instance.row = this.row
      componentRef.instance.column = this.column
      componentRef.instance.isEditing = this.isEditing
    }
  }
}
