import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DecimalPercentPipe } from 'src/app/pipes/decimalPercent.pipe'
import { FileTypeToIconPipe } from 'src/app/pipes/file-type-to-icon.pipe'
// Pipes
import { MinutesSecondsPipe } from 'src/app/pipes/minutes-seconds.pipe'
import { PercentageWithSignPipe } from 'src/app/pipes/percentage-with-sign.pipe'
import { SafePipe } from 'src/app/pipes/safe.pipe'
import { TimezonePipe } from 'src/app/pipes/timezone.pipe'
import { HourMinutesPipe } from '../../pipes/hourMinutes.pipe'
import { HtmlPipe } from '../../pipes/html.pipe'
import { MarkdownPipe } from '../../pipes/markdown.pipe'
import { MomentPipe } from '../../pipes/moment.pipe'
import { TruncatePipe } from '../../pipes/truncate.pipe'
import { WhatsappMarkdownPipe } from '../../pipes/whatsapp-markdown.pipe'
// Directives
import { BigNumberPipe } from 'src/app/pipes/bigNumber.pipe'
import { TruncateLongStringPipe } from 'src/app/pipes/truncate-long-string.pipe'
import { ClickStopPropagationDirective } from './click-stop-propagation.directive'
import { ClickStyleDirective } from './click-style.directive'
import { HtAutofocusDirective } from './ht-autofocus.directive'
import { IncrementalAnimationDirective } from './incremental-animation.directive'
import { KeydownStopPropagationDirective } from './keydown-stop-propagation.directive'
import { LoadingDirective } from './loading.directive'
import { CaseStatusPipe } from './pipes/case-status.pipe'
import { UnitPipe } from './pipes/unit.pipe'
import { TooltipDirective } from './tooltip.directive'

@NgModule({
  declarations: [
    CaseStatusPipe,
    ClickStopPropagationDirective,
    HtAutofocusDirective,
    HtmlPipe,
    KeydownStopPropagationDirective,
    MarkdownPipe,
    MomentPipe,
    TruncatePipe,
    TruncateLongStringPipe,
    UnitPipe,
    SafePipe,
    WhatsappMarkdownPipe,
    HourMinutesPipe,
    PercentageWithSignPipe,
    DecimalPercentPipe,
    MinutesSecondsPipe,
    BigNumberPipe,
    ClickStyleDirective,
    IncrementalAnimationDirective,
    TooltipDirective,
    LoadingDirective,
    TimezonePipe,
    FileTypeToIconPipe,
  ],
  exports: [
    CaseStatusPipe,
    ClickStopPropagationDirective,
    HtAutofocusDirective,
    ClickStyleDirective,
    HtmlPipe,
    KeydownStopPropagationDirective,
    MarkdownPipe,
    MomentPipe,
    TruncatePipe,
    TruncateLongStringPipe,
    UnitPipe,
    SafePipe,
    WhatsappMarkdownPipe,
    HourMinutesPipe,
    PercentageWithSignPipe,
    DecimalPercentPipe,
    MinutesSecondsPipe,
    BigNumberPipe,
    IncrementalAnimationDirective,
    TooltipDirective,
    LoadingDirective,
    TimezonePipe,
    FileTypeToIconPipe,
  ],
  imports: [CommonModule],
  providers: [
    HtmlPipe,
    MarkdownPipe,
    MomentPipe,
    TruncatePipe,
    UnitPipe,
    SafePipe,
    WhatsappMarkdownPipe,
    HourMinutesPipe,
    PercentageWithSignPipe,
    MinutesSecondsPipe,
    BigNumberPipe,
    TooltipDirective,
  ],
})
export class SharedCoreModule {}
